<template>
  <sw-tabs :card="false">
    <b-tab
      :title="$t('Statistics')"
      title-item-class="btn btn-sm p-0"
      title-link-class="px-50 py-75 h-100 d-flex align-items-center justify-content-center"
    >
      <b-row>
        <b-col
          v-for="(noteType, index) in stats"
          :key="`note_${noteType.id}_${index}`"
          sm="12"
          md="6"
          lg="4"
        >
          <b-card>
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <h2 class="mb-25 font-weight-bolder">
                  {{ noteType.count }}
                </h2>
                <span>{{ noteType.name }}</span>
              </div>
              <b-avatar size="40"
                        variant="light-primary"
              >
                <sw-icon :icon="noteType.icon" />
              </b-avatar>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-tab>

    <b-tab
      :title="$t('Notes')"
      title-item-class="btn btn-sm p-0"
      title-link-class="px-50 py-75 h-100 d-flex align-items-center justify-content-center"
    >
      <b-card
        no-body
        class="p-2 pb-0"
      >
        <b-row>
          <b-col sm="12"
                 md="6"
          >
            <sw-select :name="$t('task.Type')">
              <v-select
                v-model="filters.type"
                :options="stats"
                :reduce="i => i.id"
                @input="clearSource"
              >
                <template #option="{ name }">
                  <span>{{ name }}</span>
                </template>
                <template #selected-option="{ name }">
                  <span>{{ name }}</span>
                </template>
                <template #no-options>
                  {{ $t('NoOptions') }}
                </template>
              </v-select>
            </sw-select>
          </b-col>

          <b-col sm="12"
                 md="6"
          >
            <sw-select :name="$t('CreatedBy')">
              <assigned-users
                :value="filters.createdBy"
                :is-multiple="false"
                @input="filters.createdBy = $event"
              />
            </sw-select>
          </b-col>
        </b-row>
      </b-card>
      <div class="my-50">
        <ExportModal :export-loading="exportLoading"
                     @export="getRecordsToExport"
        />
        <!--        <b-button-->
        <!--          variant="primary"-->
        <!--          size="sm"-->
        <!--          :disabled="exportLoading"-->
        <!--          @click="getRecordsToExport"-->
        <!--        >-->
        <!--          <span v-if="!exportLoading"><feather-icon icon="DownloadIcon" />Export</span>-->
        <!--          <span v-else>-->
        <!--            <b-spinner-->
        <!--              small-->
        <!--              variant="light"-->
        <!--            />-->
        <!--          </span>-->
        <!--        </b-button>-->
      </div>
      <b-card>
        <!-- eslint-disable -->
                <sw-table
                        table-id="6af4db02948f4621898efcbe8b8261c1"
                        show-columns
                        :fields.async="tableFields"
                        :pagination="pagination"
                        @change-pagination="Object.assign(pagination, $event)"
                        @reload-content="loadData"
                        @set-fields="tableFields = $event"
                >
                    <template #table>
                        <b-table
                                :items="items"
                                :fields="fields"
                                striped
                                responsive
                                :busy="loading"
                                show-empty
                        >
                            <template #cell(content)="row">
                                <span v-b-tooltip :title="row.value" :inner-html.prop="row.value | truncate(20, '...')" />
                            </template>
                            <template #head()="item">
                                <div
                                        class="d-flex flex-row align-items-center"
                                        style="column-gap: .34rem"
                                >
                                    {{ $t(item.label) }}

                                    <feather-icon
                                            v-if="item.field.tooltip"
                                            v-b-tooltip.hover.v-primary
                                            :title="item.field.tooltip ? $t(item.field.tooltip) : ''"
                                            icon="InfoIcon"
                                    />
                                </div>
                            </template>

                            <template #cell(contact)="{ item }">
                              <span
                                      v-if="item.contactThread"
                                      class="text-nowrap"
                              >
                                <router-link :class="{ 'deleted-text': item.contactThread && item.contactThread.contact && item.contactThread.contact.deletedAt }" :to="`/contact/${item.contactThread.contact.id}/thread/${item.contactThread.id}`">
                                  {{ item.contactThread.name }} - {{ getUserName(item.contactThread.contact) | truncate(30) }}
                                </router-link>
                              </span>
                                                <span v-else>
                                <feather-icon icon="MinusIcon" />
                              </span>
                            </template>

                            <template #cell(source)="{ item }">
                                <b-badge
                                        v-if="item.contactApplicationIntegration"
                                        variant="light-primary"
                                        class="mb-25 text-capitalize"
                                >
                                    <span class="text-capitalize">{{ (item.contactApplicationIntegration.type || '').toLowerCase().replaceAll('_', ' ') }}</span>
                                </b-badge>
                                <b-badge
                                        v-if="item.integrationFacebookWebhook"
                                        variant="light-primary"
                                        class="mb-25 text-capitalize"
                                >
                                    Facebook
                                </b-badge>
                            </template>

                            <template #cell(name)="{ item }">
                                {{ getUserName(item) }}
                            </template>

                            <template #cell(servedBy)="{ value }">
                                <avatar :show-name="false" :user="value" />
                            </template>

                            <template #cell(note)="{ value }">
                                <div
                                        v-b-tooltip.hover.v-primary
                                        :title="value"
                                >{{ value | truncate(35) }}</div>
                            </template>

                            <template #cell(NumberOfSuccesses)="{ value }">
                                <b-badge variant="success">
                                    {{ value }}
                                </b-badge>
                            </template>

                            <!--    Other    -->
                            <!--    Other    -->
                            <template #cell()="row">
                                <table-default-cell
                                        :field="row.field"
                                        :value="row.value"
                                />
                            </template>

                            <!--      Empty      -->
                            <template #empty>
                                <empty-content />
                            </template>

                            <!--      Table Busy      -->
                            <template #table-busy>
                                <div class="text-center text-danger my-2">
                                    <b-spinner
                                            class="align-middle"
                                            variant="primary"
                                    />
                                </div>
                            </template>
                        </b-table>
                    </template>
                </sw-table>
            </b-card>
        </b-tab>
    </sw-tabs>
</template>

<script>
import {
  BTab, BTable, VBPopover, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import axiosIns from '@/libs/axios'
import { formatDate } from '@core/utils/filter'
import XLSX from 'xlsx'
import ExportModal from '@core/components/@sw/ExportModal.vue'
import AssignedUsers from '@/views/components/AssignedUsers.vue'

export default {
  components: {
    AssignedUsers,
    vSelect,
    BTable,
    BTab,
    ExportModal,
  },

  directives: {
    'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
  },

  data: () => ({
    loading: false,

    filters: {
      type: '',
      source: '',
      status: '',
      createdAt: '',
      createdBy: '',
    },

    pagination: {
      perPage: 25,
      totalRecords: 0,
      currentPage: 1,
    },

    filterCreatedAtOptions: [],

    items: [],
    tableFields: [
      { key: 'contact', label: 'Thread', visible: true },
      {
        key: 'contactThreadNoteType',
        label: 'ContactSource',
        visible: true,
        tag: 'b-badge',
        props: { variant: 'light-primary' },
      },
      {
        key: 'content', label: 'Content', visible: true,
      },
      {
        key: 'files', label: 'Files', visible: true, tag: 'file',
      },
      { key: 'createdAt', label: 'CreatedAt', visible: true },
      {
        key: 'createdBy', label: 'CreatedBy', visible: true, tag: 'avatar',
      },
    ],

    totalNumberSubmissions: 500,
    newSubmissions: 340,
    submissionsTaken: 240,
    rejectedSubmissions: 40,
    exportLoading: false,

    stats: [],
    connections: [],
    sources: [],
  }),

  computed: {
    fields() {
      return this.tableFields.filter(field => field.visible)
    },
    filtredSources() {
      if (this.filters.type === 'FACEBOOK') return this.connections
      return this.sources.filter(({ type }) => type === this.filters.type)
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.loadData()
      },
    },
  },

  async mounted() {
    await this.ini()
  },

  methods: {
    formatDate,
    ini() {
      this.filterCreatedAtOptions = [
        { text: this.$t('Last7Days'), value: 'last7Days' },
        { text: this.$t('ThisMonth'), value: 'thisMonth' },
        { text: this.$t('LastMonth'), value: 'lastMonth' },
        { text: this.$t('Last6Months'), value: 'last6Months' },
      ]

      this.loadData()
    },

    async loadData() {
      this.loading = true

      try {
        const resp = await axiosIns.get('1/reports/contacts/threads/notes')
        this.stats = resp.data.data || null
      } catch (err) {
        console.error(err)
      }

      const params = { orderBy: 'createdAt.desc', fields_load: 'NOTES' }

      if (this.filters.type) {
        params.eq_contactThreadNoteType = this.getObjectId(this.filters.type)
      }

      if (this.filters.createdBy) {
        params.eq_createdBy = this.getObjectId(this.filters.createdBy)
      }

      if (this.pagination) {
        params.page = this.pagination.currentPage
        params.limit = this.pagination.perPage
      }
      const response = await axiosIns.get('1/contacts/threads/notes', { params })

      this.items = response.data.data.items
      this.pagination.totalRecords = response.data.data.totalItemCount
      this.loading = false
    },
    clearSource() {
      this.filters.source = ''
    },
    async getRecordsToExport(filters = {}) {
      this.exportLoading = true
      try {
        const { total } = await this.loadExportTasks(1, 1, filters)
        const chunksToLoad = Math.ceil(total / 75)
        const requests = new Array(chunksToLoad).fill(null)
        const response = await Promise.all(requests.map((_, index) => this.loadExportTasks(index + 1, 75, filters)))

        const items = []
        response.forEach(res => {
          items.push(...res?.items.map(exportedItem => ({
            ...exportedItem,
          })))
        })

        this.$nextTick(() => {
          this.exportRecords(items)
        })
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    /* eslint-disable */
      async exportRecords(recordsList) {
          const wb = XLSX.utils.book_new()

          const order = [
              { key: 'id', label: 'ID' },
              { key: 'contactThread', label: 'task.Thread' },
              { key: 'link', label: 'Link' },
              { key: 'contactThreadNoteType', label: 'task.Type' },
              { key: 'content', label: 'task.Description' },
              { key: 'files', label: 'task.Files' },
              { key: 'createdAt', label: 'task.Created_Time' },
              { key: 'createdBy', label: 'CreatedBy' },
          ]

          const aoaToExport = [
              ...recordsList.sort((a, b) => (b.status > a.status) ? 1 : -1).map(t => order.map(({ key }) => {
                  if (key === 'contactThread') {
                      if (t[key]) return `${t[key]?.name || ''} - ${this.getUserName(t[key]?.contact)}`
                      else return `-`
                  }

                  else if (key === 'contactThreadNoteType' && t[key]) {
                      return t[key]?.name || '-'
                  }

                  else if (key === 'files') {
                      return t[key]?.length || 0
                  }

                  else if (key === 'content') {
                      return this.$options.filters.truncate(t[key], 10000, '...')
                  }

                  else if (key === 'link') {
                      return t.contactThread && t?.contactThread?.contact?.id ? `${window.location.origin}/contact/${t.contactThread.contact.id}/thread/${t.contactThread.id}/timeline` : '-'
                  }


              else if (key === 'assignedUser' || key === 'createdBy') {
                      return t[key] ? this.getUserName(t[key]) : '-'
                  }

                  else if (key === 'status') return this.$i18n.t(`task.status.${t[key]}`)
                  else if (typeof t[key] === 'boolean') return t[key] ? 1 : 0
                  else if (key === 'updatedAt' && t[key]?.date) {
                      return t.status !== 'O' ? this.formatDate(t[key].date, {
                          month: 'numeric',
                          day: 'numeric',
                          year: 'numeric'
                      }, this.$i18n.locale) : '-'
                  }
                  else if (t[key]?.date) return this.formatDate(t[key].date, { month: 'numeric', day: 'numeric', year: 'numeric' }, this.$i18n.locale)

                  return t[key] ?? ''
              }).flat(Infinity)),
          ]

          const headers = order.map(({ label }) => this.$i18n.t(label))
          aoaToExport.unshift(headers)

          const ws = XLSX.utils.aoa_to_sheet(aoaToExport);
          XLSX.utils.book_append_sheet(wb, ws, `${this.$i18n.t('Notes')}`);
          XLSX.writeFile(wb, `${this.$i18n.t('Notes')}.xlsx`);

          this.exportLoading = false
      },
      async loadExportTasks(page = 1, perPage = 1, filters) {
          const params = { orderBy: 'createdAt.asc', fields_load: 'NOTES' }

          if (filters.date?.length) {
              params.gte_createdAt = filters.date[0]
              if (filters.date[1]) {
                  params.lte_createdAt = filters.date[1]
              }
          }

          if (this.filters.type) {
              params.eq_contactThreadNoteType = this.getObjectId(this.filters.type)
          }

          if (this.filters.createdBy) {
              params.eq_createdBy = this.getObjectId(this.filters.createdBy)
          }

          params.page = page
          params.limit = perPage

          const { data } = await axiosIns.get('1/contacts/threads/notes', { params })

          return { total: data.data.totalItemCount, items: data.data.items }
      },
  },
}
</script>
