<template>
  <sw-tabs-search :tabs="tabList" />
</template>

<script>
import ReportRates from './tabs/ReportRates.vue'
import ContactsRates from './tabs/ContactsRates.vue'
import FunnelsAndProcesses from './tabs/FunnelsAndProcesses.vue'
import Applications from './tabs/Applications.vue'
import Notes from './tabs/Notes.vue'

export default {
  data: () => ({
    tabList: [
      // ReportRates
      {
        title: 'ReportRates',
        icon: 'BarChart2Icon',
        component: ReportRates,
        children: [],
      },

      // Contacts
      {
        title: 'Contacts',
        icon: 'UsersIcon',
        component: ContactsRates,
        children: [],
      },

      // FunnelsAndProcesses
      {
        title: 'FunnelsAndProcesses',
        icon: 'FilterIcon',
        component: FunnelsAndProcesses,
        children: [
          {
            title: 'FunnelProcesses',
          },
          {
            title: 'SuccessRates',
          },
          {
            title: 'FailureRates',
          },
        ],
      },

      // Applications
      {
        title: 'Applications',
        icon: 'ArchiveIcon',
        component: Applications,
        dsc: 'CloseReasons',
      },
      // Notes
      {
        title: 'Notes',
        icon: 'FileIcon',
        component: Notes,
      },
    ],
  }),
  async mounted() {
    // const resp = await axiosIns.get('1/reports/contactApplications/contactApplicationStatusReasons?fields_info=0')
    // const resp1 = await axiosIns.get('1/reports/contactThreads?fields_info=0')
    // const resp2 = await axiosIns.get('1/reports/contacts?fields_info=0')
    // console.log('contactApplications', resp)
    // console.log('contactThreads', resp1)
    // console.log('contacts', resp2)
  },
}
</script>
