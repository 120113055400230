var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sw-tabs',{attrs:{"card":false}},[_c('b-tab',{attrs:{"title":_vm.$t('Statistics'),"title-item-class":"btn btn-sm p-0","title-link-class":"px-50 py-75 h-100 d-flex align-items-center justify-content-center"}},[_c('b-row',_vm._l((_vm.stats),function(noteType,index){return _c('b-col',{key:("note_" + (noteType.id) + "_" + index),attrs:{"sm":"12","md":"6","lg":"4"}},[_c('b-card',[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',[_c('h2',{staticClass:"mb-25 font-weight-bolder"},[_vm._v(" "+_vm._s(noteType.count)+" ")]),_c('span',[_vm._v(_vm._s(noteType.name))])]),_c('b-avatar',{attrs:{"size":"40","variant":"light-primary"}},[_c('sw-icon',{attrs:{"icon":noteType.icon}})],1)],1)])],1)}),1)],1),_c('b-tab',{attrs:{"title":_vm.$t('Notes'),"title-item-class":"btn btn-sm p-0","title-link-class":"px-50 py-75 h-100 d-flex align-items-center justify-content-center"}},[_c('b-card',{staticClass:"p-2 pb-0",attrs:{"no-body":""}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('sw-select',{attrs:{"name":_vm.$t('task.Type')}},[_c('v-select',{attrs:{"options":_vm.stats,"reduce":function (i) { return i.id; }},on:{"input":_vm.clearSource},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
return [_c('span',[_vm._v(_vm._s(name))])]}},{key:"selected-option",fn:function(ref){
var name = ref.name;
return [_c('span',[_vm._v(_vm._s(name))])]}},{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('NoOptions'))+" ")]},proxy:true}]),model:{value:(_vm.filters.type),callback:function ($$v) {_vm.$set(_vm.filters, "type", $$v)},expression:"filters.type"}})],1)],1),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('sw-select',{attrs:{"name":_vm.$t('CreatedBy')}},[_c('assigned-users',{attrs:{"value":_vm.filters.createdBy,"is-multiple":false},on:{"input":function($event){_vm.filters.createdBy = $event}}})],1)],1)],1)],1),_c('div',{staticClass:"my-50"},[_c('ExportModal',{attrs:{"export-loading":_vm.exportLoading},on:{"export":_vm.getRecordsToExport}})],1),_c('b-card',[_c('sw-table',{attrs:{"table-id":"6af4db02948f4621898efcbe8b8261c1","show-columns":"","fields":_vm.tableFields,"pagination":_vm.pagination},on:{"change-pagination":function($event){return Object.assign(_vm.pagination, $event)},"reload-content":_vm.loadData,"set-fields":function($event){_vm.tableFields = $event}},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('b-table',{attrs:{"items":_vm.items,"fields":_vm.fields,"striped":"","responsive":"","busy":_vm.loading,"show-empty":""},scopedSlots:_vm._u([{key:"cell(content)",fn:function(row){return [_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],attrs:{"title":row.value},domProps:{"innerHTML":_vm._f("truncate")(row.value,20, '...')}})]}},{key:"head()",fn:function(item){return [_c('div',{staticClass:"d-flex flex-row align-items-center",staticStyle:{"column-gap":".34rem"}},[_vm._v(" "+_vm._s(_vm.$t(item.label))+" "),(item.field.tooltip)?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],attrs:{"title":item.field.tooltip ? _vm.$t(item.field.tooltip) : '',"icon":"InfoIcon"}}):_vm._e()],1)]}},{key:"cell(contact)",fn:function(ref){
var item = ref.item;
return [(item.contactThread)?_c('span',{staticClass:"text-nowrap"},[_c('router-link',{class:{ 'deleted-text': item.contactThread && item.contactThread.contact && item.contactThread.contact.deletedAt },attrs:{"to":("/contact/" + (item.contactThread.contact.id) + "/thread/" + (item.contactThread.id))}},[_vm._v(" "+_vm._s(item.contactThread.name)+" - "+_vm._s(_vm._f("truncate")(_vm.getUserName(item.contactThread.contact),30))+" ")])],1):_c('span',[_c('feather-icon',{attrs:{"icon":"MinusIcon"}})],1)]}},{key:"cell(source)",fn:function(ref){
var item = ref.item;
return [(item.contactApplicationIntegration)?_c('b-badge',{staticClass:"mb-25 text-capitalize",attrs:{"variant":"light-primary"}},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s((item.contactApplicationIntegration.type || '').toLowerCase().replaceAll('_', ' ')))])]):_vm._e(),(item.integrationFacebookWebhook)?_c('b-badge',{staticClass:"mb-25 text-capitalize",attrs:{"variant":"light-primary"}},[_vm._v(" Facebook ")]):_vm._e()]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getUserName(item))+" ")]}},{key:"cell(servedBy)",fn:function(ref){
var value = ref.value;
return [_c('avatar',{attrs:{"show-name":false,"user":value}})]}},{key:"cell(note)",fn:function(ref){
var value = ref.value;
return [_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],attrs:{"title":value}},[_vm._v(_vm._s(_vm._f("truncate")(value,35)))])]}},{key:"cell(NumberOfSuccesses)",fn:function(ref){
var value = ref.value;
return [_c('b-badge',{attrs:{"variant":"success"}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"cell()",fn:function(row){return [_c('table-default-cell',{attrs:{"field":row.field,"value":row.value}})]}},{key:"empty",fn:function(){return [_c('empty-content')]},proxy:true},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle",attrs:{"variant":"primary"}})],1)]},proxy:true}])})]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }